<template>
  <!-- 产品三级菜单 -->
  <div class="microbial">
    <!-- <div @click="back()" class="back">
      <van-icon style="font-weight: bold" size="13" name="arrow-left" />
      <span>返回</span>
    </div> -->
    <!-- 头部 -->
    <swipeTop :top_img="top_img" :ZHtitle="ZHtitle" :ENtitle="ENtitle">
    </swipeTop>
    <!-- 搜索框 -->
    <seachVue @searchName="searchName"></seachVue>
    <!-- 标签 -->
    <classifyList
      :toptype="toptype"
      :bgcolor="bgcolor"
      :checkindex="checkindex"
    ></classifyList>
    <!-- 开篇文字 -->
    <div v-if="toptext" class="OpeningWords">
      <span>{{ toptext }}</span>
    </div>
    <!-- 产品列表 -->

    <div class="listBox" :style="toptext ? 'height:34.9%' : 'height:56.7%'">
      <!-- <div class="listTitle">
        <span>
          产品列表
          <van-icon v-if="listTitle" size="18" name="arrow-down" />
        </span>
      </div> -->
      <listVue
        @todetaill="todetaill"
        :listTitle="listTitle"
        :projuctList="productList"
        :labelList="moduleList"
        @chooseMou="chooseMou"
      ></listVue>
    </div>
    <!-- 遮罩层 pdf -->
    <van-overlay :show="show" @click="show = false">
      <div class="preview" @click.stop>
        <div class="fileName">
          <img src="../../assets/img/PDF.png" alt="" />
          <div class="fileRight">
            <span class="Titlename">{{ fileItem.productName }}</span>
            <div class="bottonBox">
              <van-button
                @click="topPreview(fileItem)"
                color="#201e82"
                size="small"
                >预 览</van-button
              >
            </div>
          </div>
        </div>
        <div class="fullName">
          {{ fileItem.productName }}
        </div>
        <div class="sendmail">
          <van-field v-model="email" center clearable placeholder="请输入邮件">
            <template #button>
              <van-button
                @click="sendmailBtn(fileItem.productId)"
                size="small"
                type="primary"
                >发送邮件</van-button
              >
            </template>
          </van-field>
        </div>
      </div>
    </van-overlay>
    <!-- 遮罩层 视频 -->
    <van-overlay :show="showVideo" @click="showVideo = false">
      <div class="preview" @click.stop>
        <div class="videoBox">
          <video
            ref="myVideo"
            :poster="fileItem.pic"
            width="100%"
            :src="fileItem.fileUrl"
            controls="controls"
          ></video>
          <div class="videoNum">播放次数：{{ fileItem.characteristic }}</div>
          <div class="videoName">{{ fileItem.productName }}</div>
          <div class="videoUser">{{ fileItem.description }}</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import tag from "@/components/tag";
import listVue from "@/components/listVue.vue";
import classifyList from "@/components/classifyList.vue";
import {
  genMenuInfo,
  checkUser,
  getUserEmail,
  toFileInEmail,
  videoNum,
} from "@/api/path";
export default {
  name: "microbial",
  components: {
    swipeTop,
    listVue,
    seachVue,
    classifyList,
    tag,
  },
  data() {
    return {
      top_img: "", // 头部背景图片
      listTitle: "",
      ZHtitle: "", // 中文标题
      ENtitle: "", // 英文标题
      moduleList: [], // 分类标题
      productList: [], // 列表数据
      correlationId: 0,
      checkindex:
        Number(this.$route.query.checkindex) ||
        Number(this.$route.query.menuId),
      menuId: this.$route.query.menuId,
      parentId: 0,
      toptext: "",
      toptype: [],
      bgcolor: "",
      maxparentId: "", //拿到导航栏的父级id
      show: false,
      showVideo: false,
      fileItem: "",
      email: "",
      pid: 0,
      myVideo: null,
    };
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.backButton, false);
    }
    if (this.checkindex === 42) {
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") != -1;
      var isAndroid = ua.indexOf("android") != -1;
      var isIos = ua.indexOf("iphone") != -1 || ua.indexOf("ipad") != -1;
      if (!isWeixin) {
        document.head.innerHTML =
          '<title>抱歉，出错了</title><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0"><link rel="stylesheet" type="text/css" href="https://res.wx.qq.com/open/libs/weui/0.4.1/weui.css">';
        document.body.innerHTML =
          '<div class="weui_msg"><div class="weui_icon_area"><i class="weui_icon_info weui_icon_msg"></i></div><div class="weui_text_area"><h4 class="weui_msg_title">请在微信客户端打开链接</h4></div></div>';
      }
    }
    this.$refs.myVideo.addEventListener("play", () => {
      this.checkUser111();
    });
  },
  created() {
    this.getAllMenu();
  },
  watch: {
    $route: function (newQuestion, oldQuestion) {
      this.menuId = newQuestion.query.mid || newQuestion.query.menuId;
      this.checkindex = Number(this.$route.query.checkindex);
      this.getAllMenu();
    },
  },

  methods: {
    // 处理返回事件
    backButton() {
      if (this.parentId && (this.parentId === 11 || this.parentId === 13)) {
        this.$router.push({
          path: "/secondaryPages",
          query: { mid: this.parentId },
        });
      }
    },
    // 返回
    // back() {
    //   this.$router.go(-1);
    // },
    checkUser111() {
      checkUser()
        .then((res) => {
          this.$refs.myVideo.play();
          videoNum(this.fileItem.productId).then((res) => {
            console.log(res);
          });
        })
        .catch((err) => {
          this.$refs.myVideo.pause();
          this.$dialog
            .alert({
              message: "请先登录",
            })
            .then(() => {
              // console.log(this.$route.fullPath);
              this.$cookies.set("pathUrl", this.$route.fullPath);
              this.$router.push({
                path: "/loginPage",
              });
            });
        });
    },
    // 获取搜索返回内容
    searchName(res) {
      console.log("搜素框返回内容：", res);
    },
    // 分类选择切换
    chooseMou(item, index) {
      let params = {
        mid: item.menuId,
      };
      genMenuInfo(item.menuId, params).then((res) => {
        this.productList = res.data.productList;
      });
      this.currentSort = index;
    },
    //返回邮箱
    getEmail() {
      getUserEmail().then((res) => {
        this.email = res.data;
      });
    },
    // 获取初始数据
    getAllMenu() {
      genMenuInfo(this.menuId)
        .then((res) => {
          this.correlationId = res.data.correlationId; // 顶层id
          this.ZHtitle = res.data.menuName; // 获取中文标题
          this.pid = res.data.productId;
          this.parentId = res.data.parentId;
          this.top_img = res.data.background; // 获取头部背景图片
          this.ENtitle = res.data.menuEnglish; // 获取英文标题
          this.toptext = res.data.content; // 获取开篇文字
          this.toptype = res.data.classificationMessageAlways;
          // 获取导航标签
          // this.maxparentId = res.data.classificationMessageAlways[0].parentId;
          this.moduleList = res.data.subMenuList; // 获取分类
          // 判断是否有分类
          if (this.moduleList == null) {
            // 获取分类下内容
            this.productList = res.data.productList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 预览文件
    topPreview(item) {
      checkUser()
        .then((res) => {
          this.$router.push({
            path: "/previewPage",
            query: { item: item },
          });
        })
        .catch((err) => {
          this.$dialog
            .alert({
              message: "请先登录",
            })
            .then(() => {
              // console.log(typeof this.$route.fullPath);
              this.$cookies.set("pathUrl", this.$route.fullPath);
              this.$router.push({
                path: "/loginPage",
              });
            });
        });
    },
    // 发送邮件
    sendmailBtn(productId) {
      let params = {
        productId: productId,
        email: this.email,
      };
      toFileInEmail(params)
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.msg);
          } else {
            this.$toast.success(res.msg);
          }
        })
        .catch(() => {
          this.$dialog
            .alert({
              message: "请先登录",
            })
            .then(() => {
              this.$router.push({
                path: "/loginPage",
              });
            });
        });
    },
    // 详情页
    todetaill(item) {
      if (item.fileType === 4) {
        location.href = item.fileUrl;
      } else if (this.checkindex === 42 && item.fileType === 3) {
        this.fileItem = item;
        this.show = true;
        this.getEmail();
      } else if (this.checkindex === 42 && item.fileType === 2) {
        this.fileItem = item;
        this.showVideo = true;
      } else {
        this.$router.push({
          path: "/detailsPage",
          query: { menuid: item.productId },
        });
      }
    },
  },
  destroyed() {
    // 销毁页面返回事件
    window.removeEventListener("popstate", this.backButton, false);
  },
};
</script>
<style lang="scss" scoped>
.microbial {
  height: 100vh;
  scrollbar-width: none;
}
.back {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 3vw;
  border-radius: 50%;
  padding: 8px 10px;
  color: red;
  font-weight: bold;
  span {
    transform: scale(0.9);
  }
}
.listBox {
  background-color: #ac0a15;
  height: 34.5%;
  .listTitle {
    margin: 30px 0;
    span {
      font-size: 35px;
      font-weight: 550;
    }
  }
}
.OpeningWords {
  color: #fff;
  font-size: 3vw;
  line-height: 40px;
  height: 15%;
  /*overflow: hidden;*/
  padding: 20px 20px;
  letter-spacing: 3px;
  text-indent: 58px;
  overflow: auto;
}
.retrieve {
  background-color: #fff;
}
/deep/.van-search__content {
  background-color: #f7f8fa !important;
}
/deep/ .listTitle {
  span {
    color: #fff;
  }
}
.tag {
  background-color: #fff;
}
.van-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  .preview {
    width: 80%;
    height: 60%;
    display: flex;
    padding: 10px;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    .fileName {
      width: 100%;
      padding: 10px;
      display: flex;
      justify-content: space-around;
      border-bottom: 1px solid #ac0a15;
      img {
        width: 30%;
      }
      .fileRight {
        width: 70%;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .Titlename {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          word-wrap: break-word;
          white-space: normal;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .bottonBox {
          display: flex;
          justify-content: flex-end;
          width: 90%;
        }
      }
    }
    .fullName {
      margin-top: 10px;
      width: 100%;
      word-wrap: break-word;
      white-space: normal;
    }
    .sendmail {
      position: absolute;
      bottom: 0;
      width: 100%;
      border-top: 1px solid;
    }

    .videoNum {
      display: flex;
      justify-content: flex-end;
      color: #999999;
    }
    .videoName {
      margin: 10px 0;
    }
    .videoUser {
      color: #999999;
    }
  }
}
</style>
